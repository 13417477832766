<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body>
                <b-row class="mb-0">
                    <b-col cols="2" class="borderRight pr-0">
                        <b-card-body>
                            <b-card-title>规则图谱</b-card-title>
                            <!-- <vue-perfect-scrollbar
                            style="height: calc(100vh - 220px)"
                            class="px-2"
                        > -->
                            <b-list-group flush class="offsetMargin">
                                <b-list-group-item @click="search(false)" :style="selectLabelText === '全部'
                                        ? `cursor: pointer;border-left: 3px solid #1890ff;`
                                        : ''
                                    " style="border: 0; cursor: pointer" class="px-2">
                                    <b-icon-file-earmark-code-fill class="mr-50" />
                                    全部
                                </b-list-group-item>
                                <b-list-group-item @click="search('冷冻水系统')" :style="selectLabelText === '冷冻水系统'
                                        ? `cursor: pointer;border-left: 3px solid #1890ff;`
                                        : ''
                                    " style="border: 0; cursor: pointer" class="px-2">
                                    <b-icon-file-earmark-code-fill class="mr-50" />
                                    冷冻水系统
                                </b-list-group-item>
                                <b-list-group-item @click="search('冷却水系统')" :style="selectLabelText === '冷却水系统'
                                        ? `cursor: pointer;border-left: 3px solid #1890ff;`
                                        : ''
                                    " style="border: 0; cursor: pointer" class="px-2">
                                    <b-icon-file-earmark-code-fill class="mr-50" />
                                    冷却水系统
                                </b-list-group-item>
                                <b-list-group-item @click="search('冷水机组')" :style="selectLabelText === '冷水机组'
                                        ? `cursor: pointer;border-left: 3px solid #1890ff;`
                                        : ''
                                    " style="border: 0; cursor: pointer" class="px-2">
                                    <b-icon-file-earmark-code-fill class="mr-50" />
                                    冷水机组
                                </b-list-group-item>
                                <b-list-group-item @click="search('水泵')" :style="selectLabelText === '水泵'
                                        ? `cursor: pointer;border-left: 3px solid #1890ff;`
                                        : ''
                                    " style="border: 0; cursor: pointer" class="px-2">
                                    <b-icon-file-earmark-code-fill class="mr-50" />
                                    水泵
                                </b-list-group-item>
                                <b-list-group-item @click="search('冷却塔')" :style="selectLabelText === '冷却塔'
                                        ? `cursor: pointer;border-left: 3px solid #1890ff;`
                                        : ''
                                    " style="border: 0; cursor: pointer" class="px-2">
                                    <b-icon-file-earmark-code-fill class="mr-50" />
                                    冷却塔
                                </b-list-group-item>
                                <b-list-group-item @click="search('空调机组')" :style="selectLabelText === '空调机组'
                                        ? `cursor: pointer;border-left: 3px solid #1890ff;`
                                        : ''
                                    " style="border: 0; cursor: pointer" class="px-2">
                                    <b-icon-file-earmark-code-fill class="mr-50" />
                                    空调机组
                                </b-list-group-item>
                            </b-list-group>

                            <hr class="offsetMargin" />

                            <div>
                                <b-card-text class="font-weight-bolder">节点标签
                                </b-card-text>
                                <b-badge pill variant="light-primary" class="px-1 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryNodeLabel('故障原因')">故障原因
                                </b-badge>
                                <b-badge pill variant="light-danger" class="px-1 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryNodeLabel('故障名称')">故障名称
                                </b-badge>
                                <b-badge pill variant="light-warning" class="px-1 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="
                                        handleQueryNodeLabel('故障处理方法')
                                        ">故障处理方法
                                </b-badge>
                                <b-badge pill variant="light-success" class="px-1 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryNodeLabel('时效性')">时效性
                                </b-badge>
                                <b-badge pill variant="light-info" class="px-1 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryNodeLabel('系统')">系统
                                </b-badge>
                                <b-badge pill variant="light-secondary" class="px-1 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryNodeLabel('输入参数')">输入参数
                                </b-badge>
                                <b-badge pill variant="light-primary" class="px-1 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryNodeLabel('输入变量')">输入变量
                                </b-badge>
                            </div>

                            <hr class="offsetMargin" />

                            <div>
                                <b-card-text class="font-weight-bolder">关系类型
                                </b-card-text>

                                <b-badge variant="light-secondary" class="px-75 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryEdgeType('即时')">即时
                                </b-badge>
                                <b-badge variant="light-secondary" class="px-75 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryEdgeType('延时')">延时
                                </b-badge>
                                <b-badge variant="light-secondary" class="px-75 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryEdgeType('故障原因')">数据级
                                </b-badge>
                                <b-badge variant="light-secondary" class="px-75 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryEdgeType('故障原因')">输入变量
                                </b-badge>
                                <b-badge variant="light-secondary" class="px-75 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryEdgeType('故障原因')">故障原因
                                </b-badge>
                                <b-badge variant="light-secondary" class="px-75 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryEdgeType('故障处理方法')">故障处理方法
                                </b-badge>
                                <b-badge variant="light-secondary" class="px-75 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryEdgeType('数据级')">现象级节能诊断
                                </b-badge>
                                <b-badge variant="light-secondary" class="px-75 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryEdgeType('数据级')">现象级安全诊断
                                </b-badge>
                                <b-badge variant="light-secondary" class="px-75 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryEdgeType('数据级')">根因级诊断
                                </b-badge>
                                <b-badge variant="light-secondary" class="px-75 py-50 mr-50 mb-50 cursor-pointer"
                                    @click="handleQueryEdgeType('数据级')">输入参数
                                </b-badge>
                            </div>
                        </b-card-body>
                        <!-- </vue-perfect-scrollbar> -->
                    </b-col>

                    <b-col cols="8" class="px-0" style="height: calc(100vh - 137px)">
                        <div id="viz" style="width: 100%; height: 100%"></div>
                    </b-col>

                    <b-col cols="2" class="borderLeft pl-0">
                        <b-card-header class="font-weight-bolder">节点属性
                        </b-card-header>
                        <vue-perfect-scrollbar style="height: calc(100vh - 220px)">
                            <div v-if="nodeInfo">
                                <div class="px-2 mb-1">
                                    <b-alert variant="primary" show>
                                        <div class="alert-body">
                                            {{
                                                nodeInfo.node.raw.properties
                                                    .name
                                            }}
                                        </div>
                                    </b-alert>

                                    <b-card-text class="mb-50">
                                        <span class="bullet bullet-primary bullet-sm mr-50"></span>
                                        全部连接
                                        <b-badge variant="light-primary" class="float-right">{{
                                            dialogInfo.outEdges +
                                            dialogInfo.inEdges
                                        }}
                                        </b-badge>
                                    </b-card-text>
                                    <b-card-text class="mb-50">
                                        <span class="bullet bullet-success bullet-sm mr-50"></span>
                                        入度连接
                                        <b-badge variant="light-success" class="float-right">{{ dialogInfo.inEdges }}
                                        </b-badge>
                                    </b-card-text>
                                    <b-card-text>
                                        <span class="bullet bullet-warning bullet-sm mr-50"></span>
                                        出度连接
                                        <b-badge variant="light-warning" class="float-right">{{ dialogInfo.outEdges }}
                                        </b-badge>
                                    </b-card-text>
                                </div>

                                <hr class="my-0" />

                                <b-table-simple striped borderless small>
                                    <b-tbody>
                                        <b-tr v-for="(item,index) of Object.entries(
                                            nodeInfo.node.raw.properties
                                        )"
                                              :key="index"
                                        >
                                            <b-td class="pl-2">{{ item[0] }}
                                            </b-td>
                                            <b-td class="text-right pr-2">{{ item[1] }}
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>

                            <div v-else style="position: absolute; top: 35%" class="text-center w-100">
                                <div>
                                    <b-icon-arrow-left-circle-fill class="mr-25"></b-icon-arrow-left-circle-fill>
                                    选择节点查看信息
                                </div>
                                <b-icon-plus-circle-dotted scale="4" class="my-3" />
                                <div class="text-muted">
                                    Nothing is selected
                                </div>
                            </div>
                        </vue-perfect-scrollbar>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
// import NeoVis from 'neovis.js';
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
    name: "DataVisual",
    props: {
        height: {
            type: Number,
            //   default: () => 500,
        },
    },
    data() {
        return {
            neoViz: null,
            nodeInfo: null,
            selectLabelText: "全部",
            dialogInfo: {
                show: false,
                title: "",
                inEdges: 0,
                outEdges: 0,
            },
        };
    },
    components: {
        VuePerfectScrollbar,
    },
    methods: {
        search(name) {
            if (!name) {
                this.selectLabelText = "全部";
                this.neoViz.renderWithCypher(`MATCH p=()-[]->() RETURN p`);
                return;
            }
            this.selectLabelText = name;
            this.neoViz.renderWithCypher(
                `MATCH (n)-[r]-(m) WHERE n.name CONTAINS '${name}' RETURN n,r,m`
            );
        },
        handleQueryNodeLabel(label) {
            if (!label) {
                this.neoViz.renderWithCypher(`MATCH p=()-[]->() RETURN p`);
                return;
            }
            this.neoViz.renderWithCypher(`MATCH (n:\`${label}\`) return n`);
        },
        handleQueryEdgeType(type) {
            if (!type) {
                this.neoViz.renderWithCypher(`MATCH p=()-[]->() RETURN p`);
                return;
            }
            this.neoViz.renderWithCypher(
                `MATCH (n)-[r:\`${type}\`]-(m) RETURN r,n,m`
            );
        },
    },
    mounted() {
        const draw = () => {
            const config = {
                containerId: "viz",
                neo4j: {
                    serverUrl: "bolt://8.141.84.215:7687",
                    serverUser: "neo4j",
                    serverPassword: "rootroot",
                },
                visConfig: {
                    nodes: {
                        shape: "circle",
                        size: 30,
                    },
                    edges: {
                        arrows: {
                            to: { enabled: true },
                        },
                    },
                },
                labels: {
                    // 为特定节点类型配置标签
                    "*": {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (node) => {
                                    return `${node.properties.name.length > 4
                                            ? node.properties.name.substr(
                                                0,
                                                4
                                            ) + "..."
                                            : node.properties.name
                                        }`;
                                },
                                color: () => {
                                    return {
                                        background: "#ec807a5f",
                                    };
                                },
                            },
                        },
                    },
                    时效性: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (node) => {
                                    return `${node.properties.name.length > 4
                                            ? node.properties.name.substr(
                                                0,
                                                4
                                            ) + "..."
                                            : node.properties.name
                                        }`;
                                },
                                color: () => {
                                    return {
                                        background: "#28c76f5f",
                                    };
                                },
                            },
                        },
                    },
                    系统: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (node) => {
                                    return `${node.properties.name.length > 4
                                            ? node.properties.name.substr(
                                                0,
                                                4
                                            ) + "..."
                                            : node.properties.name
                                        }`;
                                },
                                color: () => {
                                    return {
                                        background: "#00cfe85f",
                                    };
                                },
                            },
                        },
                    },
                    故障名称: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (node) => {
                                    return `${node.properties.name.length > 4
                                            ? node.properties.name.substr(
                                                0,
                                                4
                                            ) + "..."
                                            : node.properties.name
                                        }`;
                                },
                                color: () => {
                                    return {
                                        background: "#ea54555f",
                                    };
                                },
                            },
                        },
                    },
                    输入变量: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (node) => {
                                    return `${node.properties.name.length > 4
                                            ? node.properties.name.substr(
                                                0,
                                                4
                                            ) + "..."
                                            : node.properties.name
                                        }`;
                                },
                                color: () => {
                                    return {
                                        background: "#1890ff5f",
                                    };
                                },
                            },
                        },
                    },
                    故障原因: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (node) => {
                                    return `${node.properties.name.length > 4
                                            ? node.properties.name.substr(
                                                0,
                                                4
                                            ) + "..."
                                            : node.properties.name
                                        }`;
                                },
                                color: () => {
                                    return {
                                        background: "#84e1ea5f",
                                    };
                                },
                            },
                        },
                    },
                    故障处理方法: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (node) => {
                                    return `${node.properties.name.length > 4
                                            ? node.properties.name.substr(
                                                0,
                                                4
                                            ) + "..."
                                            : node.properties.name
                                        }`;
                                },
                                color: () => {
                                    return {
                                        background: "#ff9f435f",
                                    };
                                },
                            },
                        },
                    },
                    输入参数: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (node) => {
                                    return `${node.properties.name.length > 4
                                            ? node.properties.name.substr(
                                                0,
                                                4
                                            ) + "..."
                                            : node.properties.name
                                        }`;
                                },
                                color: () => {
                                    return {
                                        background: "#82868b5f",
                                    };
                                },
                            },
                        },
                    },
                },
                relationships: {
                    即时: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (edge) => {
                                    return edge.type;
                                },
                            },
                        },
                    },
                    延时: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (edge) => {
                                    return edge.type;
                                },
                            },
                        },
                    },
                    数据级: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (edge) => {
                                    return edge.type;
                                },
                            },
                        },
                    },
                    输入变量: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (edge) => {
                                    return edge.type;
                                },
                            },
                        },
                    },
                    故障原因: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (edge) => {
                                    return edge.type;
                                },
                            },
                        },
                    },
                    故障处理方法: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (edge) => {
                                    return edge.type;
                                },
                            },
                        },
                    },
                    现象级节能诊断: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (edge) => {
                                    return edge.type;
                                },
                            },
                        },
                    },
                    现象级安全诊断: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (edge) => {
                                    return edge.type;
                                },
                            },
                        },
                    },
                    根因级诊断: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (edge) => {
                                    return edge.type;
                                },
                            },
                        },
                    },
                    输入参数: {
                        [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
                            function: {
                                label: (edge) => {
                                    return edge.type;
                                },
                            },
                        },
                    },
                },
                arrows: true,
                hierarchical: true,
                hierarchical_sort_method: "directed",
                initialCypher: "MATCH p=()-[]->() RETURN p",
            };
            this.neoViz = new NeoVis.default(config);
            this.neoViz.render();
            // console.log(this.neoViz.registerOnEvent, 'neoVizneoViz')
            this.neoViz.registerOnEvent("clickNode", (e) => {
                this.nodeInfo = JSON.parse(JSON.stringify(e));
            });
        };

        setTimeout(() => {
            draw();
        }, 100);
    },
};
</script>

<style lang="scss" scoped>
/* Lucas */
.offsetMargin {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

.listGroupFilter .list-group-item {
    border: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
</style>
